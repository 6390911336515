import MaxWidth from 'components/functional/maxWidth'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const IntroStyles = styled.section`
  width: 100%;
  text-align: center;
  padding: 2rem 0 0 0;
  h2 {
    margin: 1rem 0 2rem 0;
    line-height: 2.85rem;
  }
  p {
    a {
      display: block;
      color: var(--main);
      &:hover {
        color: var(--white);
      }
    }
  }
  @media ${media.sm} {
    padding: 4rem 0;
    width: 90%;
    margin: var(--auto);
  }
  @media ${media.md} {
    padding-bottom: 2rem;
  }
  @media ${media.lg} {
    padding-bottom: 4rem;
    width: 75%;
    h2 {
      font-size: 2.35rem;
      margin: 0 0 2rem 0;
    }
  }
  @media ${media.xl} {
    width: 100%;
    p {
      font-size: 1.25rem;
      width: 50%;
      margin: var(--auto);
    }
  }
`
const Intro = () => {
  const i = true
  return (
    <MaxWidth>
      <IntroStyles>
        <h2>Performances now on sale until 1 March 2025</h2>
        <p>
          This thrilling West End production is THE genre-defining murder
          mystery from the best-selling novelist of all time… case closed!
        </p>
        <p>
          Book direct and book with confidence, with no quibble exchanges.{' '}
          <Link to="/ticket-information">
            For more information visit our Ticket Info page.
          </Link>
        </p>
      </IntroStyles>
    </MaxWidth>
  )
}

export default Intro
